<template>
  <div class="xtx-cart-page">
    <div class="container">
      <div class="aboutUs"></div>
      <p class="top-center">COMPANY PROFILE</p>
      <p class="company_text">公司简介</p>
      <p>胜邦信息科技有限公司是一家聚焦医疗网络及医疗物联网技术研发与
应用、专注于研发、生产和销售以医院和养老机构等应用环境下的音视频
信息智能交互产品，专业为医院行业和养老护理需求提供全面的智慧解决
方案，包括智慧护理、智慧病房和智慧养老、医疗物联网等。公司拳头产
品有 : 护理信息对讲系统、信息发布系统、排队叫号系统。
公司紧跟前沿网络通信及多媒体技术，以研发驱动发展，坚持自主创
新，推出的系列产品具有医疗级的可靠性、稳定性、安全性、易维护性 ,
同时具有高度的可拓展性和兼容性，支持全系列移动医疗及医疗物联网应
用，可实现丰富的医护应用场景。
未来，我们以医护信息交互为基础，全面拓展技术相关应用的细分领
域，将服务能力延伸，不断为相关应用领域提供独有、高效、安全、稳定
的深度定制解决方案。我们坚持以客户需求为导向、客户即上帝，以学无
止境的精神向同行学习，在新时代国家卫健委的智慧医疗、智慧服务、智
慧管理“ 三位一体 ”的智慧医护系统建设号召下，砥砺前行，力争在医护
智能化建设领域成为国内领先、国际一流的高新技术企业 !</p>
    </div>
  </div>
</template>
<script>

export default {
  name: 'XtxCartPage',
  setup () {}
}
</script>
<style scoped lang="less">
.container{
  width: 1024px;
  height: 100%;
  p{
    color: #646464;
    text-align: left;
    font-size: 20px;
    margin-bottom: 20px;
    line-height: 36px;
  }
  .aboutUs{
    width: 1024px;
    height: 400px;
    background: url('../../assets/images/aboutUs.jpg') no-repeat center / contain;
  }
  .company_text{
    text-align: center;
    font-size: 30px;
  }
  .top-center{
    text-align: center;
    font-size: 40px;
    color: #0d4596;
  }
}
</style>
